import PaginationRequest from '@/common/components/pagination/pagination-request'
import Period from '@/common/components/periods/period'
import Invoice from '@/invoices/models/invoice'
import Ride from '@/rides/models/ride'
import RidesFilter from '@/rides/models/rides-filter'
import ridesCalendarService from '@/rides/services/rides-calendar-service'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import 'moment-duration-format'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesMultiselectDialog extends Vue {

  @Prop({ required: true })
  private invoice!: Invoice

  @Prop({ default: false })
  private readonly!: boolean

  private dialog: boolean = false

  private rides: Ride[] = []
  private selected: Ride[] = []
  private loading: boolean = false

  private filter = new RidesFilter()
  private pagination = new PaginationRequest()

  public async open(selected: Ride[] = []) {
    this.selected = cloneDeep(selected)
    this.dialog = true

    // Sort by date descending.
    this.pagination.sortBy = ['date']
    this.pagination.sortDesc = [true]
    // Reset list of rides to first page.
    this.pagination.page = 1
    // Set filter period to current week.
    this.filter.period = new Period()
    this.filter.period.start = moment().startOf('month').format('YYYY-MM-DD')
    this.filter.period.end = moment().endOf('month').format('YYYY-MM-DD')
    // Only rides that have a price.
    this.filter.onlyWithCost = true
    this.filter.excludeDirectBilling = true

    await this.fetchAll()
  }

  private async resetPageAndFetchAll() {
    this.pagination.page = 1
    await this.fetchAll()
  }

  private async fetchAll() {
    this.loading = true
    const response = await ridesCalendarService.multiselectAllAsync(
      this.invoice.id,
      this.filter,
      this.pagination)
    this.rides = response.items
    this.pagination.totalItems = response.totalItems
    this.loading = false
  }

  private select() {
    this.$emit('select', this.selected)
    this.dialog = false
  }

  private cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  @Watch('pagination', { deep: true })
  private async paginationChanged(newVal: PaginationRequest, oldVal: PaginationRequest) {
    if (newVal !== oldVal) {
      await this.fetchAll()
    }
  }
}
