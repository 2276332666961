import reportsService from "@/reports/services/reports-service";
import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import CountryRevenue from "../entities/country-revenue";
import CustomerRevenue from "../entities/customer-revenue";
import CustomerCategoryRevenue from "../entities/customer-category-revenue";
import RideCategoryRevenue from "../entities/ride-category-revenue";
import DriverRevenue from "../entities/driver-revenue";
import RouteRevenue from "../entities/route-revenue";
import moment from "moment";

// import { Bar } from 'vue-chartjs'

@Component
export default class Reports extends Vue {
  private menuFrom = false;
  private dateFrom = moment().format("YYYY-MM-DD");
  private menuTo = false;
  private dateTo = moment().format("YYYY-MM-DD");

  private revenuesByCustomer = new Array<CustomerRevenue>();
  private revenuesByCustomerCategory = new Array<CustomerCategoryRevenue>();
  private revenuesByRideCategory = new Array<RideCategoryRevenue>();
  private revenuesByCountry = new Array<CountryRevenue>();
  private revenuesByDriver = new Array<DriverRevenue>();
  private revenuesByRoute = new Array<RouteRevenue>();
  private numberFormatter = new Intl.NumberFormat("de", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });

  @Watch("dateFrom")
  dateFromChanged() {
    this.updateStatistics();
  }

  @Watch("dateTo")
  dateToChanged() {
    this.updateStatistics();
  }

  private async updateStatistics() {
    this.revenuesByCustomer = await reportsService.revenuesByCustomer(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    );
    this.revenuesByCustomerCategory = await reportsService.revenuesByCustomerCategory(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    );
    this.revenuesByRideCategory = await reportsService.revenuesByRideCategory(
      new Date(this.dateFrom),
      new Date(this.dateFrom)
    );
    this.revenuesByCountry = await reportsService.revenuesByCountry(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    );
    this.revenuesByDriver = await reportsService.revenuesByDriver(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    );
    this.revenuesByRoute = await reportsService.revenuesByRoute(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    );
  }

  public exportRevenuesByCustomer() {
    this.csvExportRevenuesByCustomer();
  }
  /**
   * exportRevenuesByCustomerCategory
   */
  public exportRevenuesByCustomerCategory() {
    this.csvExportRevenuesByCustomerCategory();
  }

  public exportRevenuesByCountry() {
    this.csvExportRevenuesByCountry();
  }

  public exportRevenuesByDriver() {
    this.csvExportRevenuesByDriver();
  }

  public exportRevenuesByRoute() {
    this.csvExportRevenuesByRoute();
  }

  private async csvExportRevenuesByRoute() {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFFSteuersatz;Umsatz\n";

    let totalSum = 0;

    this.revenuesByRoute.forEach((revenue) => {
      csvContent +=
        revenue.route +
        ";" +
        this.numberFormatter.format(revenue.revenue) +
        "\r\n";

      totalSum += revenue.revenue;
    });

    csvContent += "Gesamt;" + this.numberFormatter.format(totalSum) + "\r\n";

    this.makeFile(csvContent, "UmsatzSteuersatz.csv");
  }

  private async csvExportRevenuesByDriver() {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFFFahrer;Umsatz\n";

    let totalSum = 0;

    this.revenuesByDriver.forEach((revenue) => {
      csvContent +=
        revenue.driverName +
        ";" +
        this.numberFormatter.format(revenue.revenue) +
        "\r\n";

      totalSum += revenue.revenue;
    });

    csvContent += "Gesamt;" + this.numberFormatter.format(totalSum) + "\r\n";

    this.makeFile(csvContent, "UmsatzFahrer.csv");
  }

  private async csvExportRevenuesByCountry() {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFFLand;Umsatz\n";

    let totalSum = 0;

    this.revenuesByCountry.forEach((revenue) => {
      csvContent +=
        revenue.country +
        ";" +
        this.numberFormatter.format(revenue.revenue) +
        "\r\n";

      totalSum += revenue.revenue;
    });

    csvContent += "Gesamt;" + this.numberFormatter.format(totalSum) + "\r\n";

    this.makeFile(csvContent, "UmsatzLand.csv");
  }

  private async csvExportRevenuesByCustomerCategory() {
    let csvContent =
      "data:text/csv;charset=utf-8,\uFEFFKundenkategorie;Umsatz\n";

    let totalSum = 0;

    this.revenuesByCustomerCategory.forEach((revenue) => {
      csvContent +=
        revenue.customerCategoryName +
        ";" +
        this.numberFormatter.format(revenue.revenue) +
        "\r\n";

      totalSum += revenue.revenue;
    });

    csvContent += "Gesamt;" + this.numberFormatter.format(totalSum) + "\r\n";

    this.makeFile(csvContent, "UmsatzKundenkategorie.csv");
  }

  private async csvExportRevenuesByCustomer() {
    let csvContent = "data:text/csv;charset=utf-8,\uFEFFKunde;Anzahl;Umsatz\r\n";

    let totalNum = 0;
    let totalSum = 0;

    this.revenuesByCustomer.forEach((revenue) => {
      csvContent +=
        revenue.customerName +
        ";" +
        revenue.numRides +
        ";" +
        this.numberFormatter.format(revenue.revenue) +
        "\r\n";

      totalNum += revenue.numRides;
      totalSum += revenue.revenue;
    });

    csvContent +=
      "Gesamt;" +
      totalNum +
      ";" +
      this.numberFormatter.format(totalSum) +
      "\r\n";

    this.makeFile(csvContent, "UmsatzKunde.csv");
  }

  private makeFile(csvContent: string, fileName: string) {
    const data = encodeURI(csvContent);
    const link = document.createElement("a");

    link.setAttribute("href", data);
    link.setAttribute("download", fileName);
    link.click();
  }

  private async mounted() {
    this.updateStatistics();
  }
}
