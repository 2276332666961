import RideCategory from '@/rides/models/ride-category'
import ridesService from '@/rides/services/rides-service'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RideCategorySelect extends Vue {

  @Prop({ default: null })
  private value!: RideCategory | null

  @Prop({ default: false })
  private disabled!: boolean

  private loading: boolean = false
  private category: RideCategory | null = this.value
  private categories: RideCategory[] = this.value ? [this.value] : []
  private search: string = ''

  @Watch('value', { deep: true })
  private watchValue(val: RideCategory, oldVal: RideCategory) {
    if (val === null) {
      this.category = null
      this.categories = []
    } else {
      this.category = val
      this.categories = [val]
    }
  }

  @Watch('rideCategory', { deep: true })
  private watchCustomer(val: RideCategory, oldVal: RideCategory) {
    if (val !== oldVal) {
      this.$emit('input', val)
    }
  }


  private select(category: RideCategory) {
    this.category = category
  }
}
