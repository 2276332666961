import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import PaginationRequest from '@/common/components/pagination/pagination-request'
import PaginationResponse from '@/common/components/pagination/pagination-response'
import Period from '@/common/components/periods/period'
import Ride from '@/rides/models/ride'
import RidesFilter from '@/rides/models/rides-filter'
import moment, { Moment } from 'moment'
import { RidesOrder } from '../models/rides-order'

class RidesCalendarService {

  public async findAllAsync(filter: RidesFilter) {
    const url = `ridescalendar`
    const res = await axios.post<Ride[]>(url, filter)
    return Responses.list(res)
  }

  public async findOpenAsync(period: Period) {
    const filter = new RidesFilter()
    filter.openRidesOnly = true
    filter.period = period
    return await this.findAllAsync(filter)
  }

  public async findShiftAsync(period: Period, driversRidesOnly: boolean) {
    const filter = new RidesFilter()
    filter.order = RidesOrder.DateAsc
    filter.period = period
    filter.driversRidesOnly = driversRidesOnly
    return await this.findAllAsync(filter)
  }

  public async findAllPagedAsync(filter: RidesFilter, paging: PaginationRequest) {
    const url = `ridescalendar/paged`
    const res = await axios.post<PaginationResponse<Ride>>(url, {
      filter: filter,
      paging: paging,
    })
    return Responses.pagedList(res)
  }

  public async multiselectAllAsync(invoiceId: string, filter: RidesFilter, paging: PaginationRequest) {
    const url = `ridescalendar/multiselect`
    const res = await axios.post<PaginationResponse<Ride>>(url, {
      invoiceId: invoiceId,
      filter: filter,
      paging: paging,
    })
    return Responses.pagedList(res)
  }
}

export default new RidesCalendarService()
