import PagedMixin from '@/app/mixins/paged-mixin'
import Ride from '@/rides/models/ride'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesMultiselectTable extends Mixins(PagedMixin) {

  @Prop({ default: () => [] })
  private value!: Ride[]

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: () => [] })
  private rides!: Ride[]

  private headers = [
    { text: '', value: 'template', sortable: false },
    { text: 'Auftrag', value: 'jobNr', sortable: false },
    { text: 'Zeitpunkt', value: 'date' },
    { text: 'Kunde', value: 'customerName', sortable: false },
    { text: 'Betrag', value: 'cost' },
    { text: 'Route', value: 'itineraryName' },
    { text: 'Zahlung', value: 'share', sortable: false },
    // { text: 'Strecke / Kommentar', value: 'remarks' },
  ]

  private selected = this.value

  @Watch('value', { deep: true })
  private valueChanged(val: Ride[], oldVal: Ride[]) {
    if (val !== oldVal) {
      this.selected = val
    }
  }

  @Watch('selected', { deep: true })
  private selectedChanged(val: Ride[], oldVal: Ride[]) {
    if (val !== oldVal) {
      this.$emit('input', val)
    }
  }
}
