





















































































import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator"
import moment from "moment"
import reportsService from "@/reports/services/reports-service"
import DashboardStatisticsItem from "../entities/dashboard-statistics-item"

@Component
export default class RidesTimeTable extends Vue {
  @Prop({ required: true })
  private title!: string
  private menuFrom = false
  private dateFrom = moment().format("YYYY-MM-DD")
  private menuTo = false
  private dateTo = moment().format("YYYY-MM-DD")
  private dashboardStatistics = new Array<DashboardStatisticsItem>()

  @Watch("dateFrom")
  dateFromChanged() {
    this.updateStatistics()
  }

  @Watch("dateTo")
  dateToChanged() {
    this.updateStatistics()
  }

  private async updateStatistics() {
    this.dashboardStatistics = await reportsService.getDashboardStatistics(
      new Date(this.dateFrom),
      new Date(this.dateTo)
    )
  }

  private async mounted() {
    this.updateStatistics()
  }
}
