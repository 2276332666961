import Guid from '@/app/utils/guid'
import Ride from '@/rides/models/ride'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesList extends Vue {

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: () => [] })
  private rides!: Ride[]

  private getMapsUrl(ride: Ride) {
    return `http://maps.google.com/maps/dir/${encodeURIComponent(ride.origin)}/${encodeURIComponent(ride.destination)}`
  }
}
