import Dates from '@/app/utils/dates'
import SelectItem from '@/common/components/selection/select-item'
import RRule, { ByWeekday, Frequency, Options, Weekday, WeekdayStr } from 'rrule'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RecurrenceDialog extends Vue {

  private dialog = false
  private editMode = false
  private options: Partial<Options> = this.defaultOptions()

  private frequencies = [
    { id: Frequency.DAILY, name: 'Tage' },
    { id: Frequency.WEEKLY, name: 'Wochen' },
    { id: Frequency.MONTHLY, name: 'Monate' },
  ]

  private weekdays = [
    { id: RRule.MO, name: 'Mo' },
    { id: RRule.TU, name: 'Di' },
    { id: RRule.WE, name: 'Mi' },
    { id: RRule.TH, name: 'Do' },
    { id: RRule.FR, name: 'Fr' },
    { id: RRule.SA, name: 'Sa' },
    { id: RRule.SU, name: 'So' },
  ]

  private months = [
    { id: 1, name: 'Jan' },
    { id: 2, name: 'Feb' },
    { id: 3, name: 'Mär' },
    { id: 4, name: 'Apr' },
    { id: 5, name: 'Mai' },
    { id: 6, name: 'Jun' },
    { id: 7, name: 'Jul' },
    { id: 8, name: 'Aug' },
    { id: 9, name: 'Sep' },
    { id: 10, name: 'Okt' },
    { id: 11, name: 'Nov' },
    { id: 12, name: 'Dez' },
  ]

  private selectedDaysOfMonth: SelectItem[] = []

  private endMode: 'never' | 'count' | 'until' = 'count'
  private datePickerMenu = false

  private get isWeekly() {
    return this.options.freq === Frequency.WEEKLY
  }

  private get isMonthly() {
    return this.options.freq === Frequency.MONTHLY
  }

  private get daysOfMonth() {
    const list: SelectItem[] = []
    for (let i = 1; i <= 31; i++) {
      list.push({ value: i, text: `${i}.` })
    }
    list.push({ value: -1, text: 'Monatsende' })
    return list
  }

  public openRFC(ical?: string) {
    const cal = ical || 'FREQ=WEEKLY;INTERVAL=1;COUNT=1'
    this.open(RRule.parseString(cal))
  }

  public open(options?: Partial<Options>) {
    this.options = options || this.defaultOptions()
    // Auswahl für das Terminende setzen.
    if (this.options.count && this.options.count > 0) {
      this.endMode = 'count'
    } else if (this.options.until) {
      this.endMode = 'until'
    } else {
      this.endMode = 'never'
    }
    this.dialog = true
  }

  @Watch('options.freq')
  private optionsFreqChanged(val: Frequency, oldVal: Frequency) {
    if (val !== oldVal) {
      switch (val) {
        case Frequency.DAILY:
          delete this.options.byweekday
          delete this.options.bymonth
          delete this.options.bymonthday
          break
        case Frequency.WEEKLY:
          delete this.options.bymonth
          delete this.options.bymonthday
          break
        case Frequency.MONTHLY:
          delete this.options.byweekday
          break
        default:
          break
      }
    }
  }

  @Watch('endMode')
  private endModeChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      switch (val) {
        case 'until':
          delete this.options.count
          break
        case 'count':
          delete this.options.until
          break
        default:
          delete this.options.count
          delete this.options.until
          break
      }
    }
  }

  private defaultOptions(): Partial<Options> {
    return {
      freq: Frequency.WEEKLY,
      interval: 1,
      count: 1,
    }
  }

  private async save() {
    if (await this.$validator.validateAll()) {
      this.$emit('save', this.options)
      this.dialog = false
    }
  }

  private cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  private weekdaySelected(wd: Weekday) {
    return this.weekdayIncluded(this.options, wd)
  }

  private toggleWeekday(wd: Weekday) {
    if (!this.options.byweekday) {
      this.$set(this.options, 'byweekday', [wd])
    } else if (Array.isArray(this.options.byweekday)) {
      if (this.weekdayIncluded(this.options, wd)) {
        this.options.byweekday = this.options.byweekday.filter((d) => d !== wd)
        if (this.options.byweekday.length === 0) {
          delete this.options.byweekday
        }
      } else {
        this.options.byweekday.push(wd)
      }
    }
  }

  private weekdayIncluded(options: Partial<Options>, wd: Weekday) {
    if (Array.isArray(options.byweekday)) {
      for (const day of options.byweekday) {
        if (typeof day === 'object') {
          if (day.equals(wd)) {
            return true
          }
        }
      }
      return false
    }
  }

  private monthSelected(mn: number) {
    return this.monthIncluded(this.options, mn)
  }

  private toggleMonth(mn: number) {
    if (!this.options.bymonth) {
      this.$set(this.options, 'bymonth', [mn])
    } else if (Array.isArray(this.options.bymonth)) {
      if (this.monthIncluded(this.options, mn)) {
        this.options.bymonth = this.options.bymonth.filter((d) => d !== mn)
        if (this.options.bymonth.length === 0) {
          delete this.options.bymonth
        }
      } else {
        this.options.bymonth.push(mn)
      }
    }
  }

  private monthIncluded(options: Partial<Options>, mn: number) {
    if (Array.isArray(options.bymonth)) {
      return options.bymonth.includes(mn)
    }
    return false
  }
}
