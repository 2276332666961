import Ride from '@/rides/models/ride'
import { RidePaymentMethod } from '@/rides/models/ride-payment-method'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RideStateBadge extends Vue {

  @Prop()
  private ride!: Ride

  get paymentMethodColor() {
    switch (this.ride.paymentMethod) {
      case RidePaymentMethod.Cash:
        return '#1A9115'
      case RidePaymentMethod.Invoice:
        return 'primary'
    }
  }
}
