import axios from "@/app/axios";
import Responses from "@/app/utils/responses";
import CrudService from "@/common/services/crud-service";
import CustomerRevenue from "@/reports/entities/customer-revenue";
import DriverRevenue from "@/reports/entities/driver-revenue";
import RouteRevenue from "@/reports/entities/route-revenue";
import DashboardStatisticsItem from "@/rides/entities/dashboard-statistics-item";
import CountryRevenue from "../entities/country-revenue";
import CustomerCategoryRevenue from "../entities/customer-category-revenue";
import RideCategoryRevenue from "../entities/ride-category-revenue";

class ReportsService extends CrudService<CustomerRevenue> {
  public async revenuesByCustomer(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByCustomer`;
    const res = await axios.post<CustomerRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async getDashboardStatistics(start: Date, end: Date) {
    const url = `${this.resource}/getDashboardStatistics`;
    const res = await axios.post<DashboardStatisticsItem[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async revenuesByCustomerCategory(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByCustomerCategory`;
    const res = await axios.post<CustomerCategoryRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async revenuesByRideCategory(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByRideCategory`;
    const res = await axios.post<RideCategoryRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async revenuesByCountry(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByCountry`;
    const res = await axios.post<CountryRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async revenuesByDriver(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByDriver`;
    const res = await axios.post<DriverRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  public async revenuesByRoute(start: Date, end: Date) {
    const url = `${this.resource}/revenuesByRoute`;
    const res = await axios.post<RouteRevenue[]>(url, {
      start: start,
      end: end,
    });
    return Responses.list(res);
  }

  /**
     * async getPdf
id     */
  public async getPdf(id: string) {
    const url = `${this.resource}/pdf?id=${id}`;
    const pdf = await axios.get(url);
    return Responses.obj(pdf);
  }
}

export default new ReportsService("statistics");
