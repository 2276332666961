import Period from '@/common/components/periods/period'
import XConfirmDialog from '@/common/components/x-confirm-dialog/x-confirm-dialog'
import Itinerary from '@/itineraries/models/itinerary'
import RidesDialog from '@/rides/components/rides-dialog.vue'
import Ride from '@/rides/models/ride'
import drop from 'lodash/drop'
import take from 'lodash/take'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesTimeTable extends Vue {

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: false })
  private loadingItineraries!: boolean

  @Prop({ default: () => [] })
  private itineraries!: Itinerary[]

  @Prop({ required: true })
  private title!: string

  @Prop({ default: () => [] })
  private rides!: Ride[]

  @Prop({ default: 0 })
  private count!: number

  @Prop({ default: '' })
  private actionTitle!: string

  @Prop({ default: false })
  private loadingAction!: boolean

  @Prop({ default: null })
  private action!: () => void | null

  @Prop({ default: '' })
  private action2Title!: string

  @Prop({ default: false })
  private loadingAction2!: boolean

  @Prop({ default: null })
  private action2!: () => void | null

  @Prop({ default: null })
  private period!: Period | null

  @Prop({ default: 0 })
  private pageSize!: number

  @Prop()
  private icon!: string

  @Prop({ type: Boolean, default: false})
  private dateSelectionDisabled!: boolean 

  @Ref()
  private confirmDialog!: XConfirmDialog

  @Ref()
  private dialog!: RidesDialog

  private pPeriod = this.period

  private page = 1

  private get pagedRides() {
    if (this.pageSize > 0) {
      const offset = (this.page - 1) * this.pageSize
      return take(drop(this.rides, offset), this.pageSize)
    }
    return this.rides
  }

  private get numPages() {
    return Math.ceil(this.count / this.pageSize)
  }

  private create(ride: Ride) {
    this.dialog.create(ride)
  }

  private edit(ride: Ride) {
    this.dialog.edit(ride)
  }

  private showAll() {
    this.$router.push('rides')
  }

  private save(ride: Ride) {
    this.$emit('save', ride)
  }

  private update(ride: Ride) {
    this.$emit('update', ride)
  }

  private async remove(ride: Ride) {
    this.confirmDialog.open(ride)
  }

  private async removeConfirmed(ride: Ride) {
    this.$emit('remove', ride)
  }

  private navigate() {
    this.$emit('update:period', this.pPeriod)
  }

  // get periodTitle() {
  //   if (this.period) {
  //     const start = moment(this.period.start)
  //     const end = moment(this.period.end)
  //     return Dates.periodText(start, end, 'seconds')
  //   }
  //   return ''
  // }
}
