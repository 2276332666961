import axios from '@/app/axios'
import Responses from '@/app/utils/responses'
import CrudService from '@/common/services/crud-service'
import RideCategory from '@/rides/models/ride-category'
import Ride from '@/rides/models/ride'
import User from '@/users/entities/user'

class RidesService extends CrudService<Ride> {

  public async findAllForInvoiceAsync(id: string) {
    const url = `${this.resource}/invoice`
    const res = await axios.get<Ride[]>(url, {
      params: {
        id: id,
      },
    })
    return Responses.list(res)
  }

  public async updateRemarksAsync(entity: Ride) {
    const res = await axios.put<Ride>(`${this.resource}/updateRemarks`, entity)
    return Responses.obj(res)
  }

  public async totalCashTodayAsync() {
    const url = `${this.resource}/cash/total/today`
    const res = await axios.get<number>(url)
    return Responses.obj(res)
  }

  /**
   * async getRideCategoriesAsync
   */
  public async getRideCategoriesAsync() {
    const res = await axios.get<RideCategory[]>(`${this.resource}/getRideCategoriesAsync`)
    return Responses.list(res)
  }

  //const url = `${this.resource}' public async deleteAsync(id: string) `
  //   await axios.post(`${this.resource}/${id}/delete`/rideCategories)
  // }
  public async saveCategoryAsync(entity: RideCategory) {
    const res = await axios.post<RideCategory>(`${this.resource}/createCategory`, entity)
    return Responses.obj(res)
  }

  public async updateCategoryAsync(entity: RideCategory) {
    const res = await axios.put<RideCategory>(`${this.resource}/updateCategory`, entity)
    return Responses.obj(res)
  }

  public async removeCategoryAsync(id: string) {
    await axios.post(`${this.resource}/${id}/deleteCategory`)
  }
  
  public async getDriversAsync() {
    const res = await axios.get<User[]>(`${this.resource}/drivers`)
    return Responses.list(res)
  }
}

export default new RidesService('rides')
