import snacks from '@/app/services/snacks'
import Ride from '@/rides/models/ride'
import ridesService from '@/rides/services/rides-service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RidePaymentMethod } from '@/rides/models/ride-payment-method'

@Component
export default class QuickRide extends Vue {

  private cost = ''
  private remarks = ''
  private totalCash: number | null = null

  get costFloat() {
    return this.cost ? parseFloat(this.cost.replace(',', '.')) : 0
  }

  get isInvalid() {
    return !this.cost || isNaN(this.costFloat)
  }

  private async book() {
    const ride = new Ride()
    ride.remarks = this.remarks
    ride.cost = this.costFloat
    ride.isQuickRide = true
    ride.paymentMethod = RidePaymentMethod.Cash
    const res = await ridesService.createAsync(ride)
    if (res) {
      await this.loadTotalCashAsync()
      snacks.success(`Buchung über ${this.cost} € erfolgt.`)
      this.cost = ''
      this.remarks = ''
      this.$emit('booked', res)
    } else {
      snacks.error('Buchung fehlgeschlagen.')
    }
  }

  private async loadTotalCashAsync() {
    this.totalCash = await ridesService.totalCashTodayAsync()
  }

  private async mounted() {
    await this.loadTotalCashAsync()
  }
}
