import Period from '@/common/components/periods/period'
import Ride from '@/rides/models/ride'
import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesAgendaDay extends Vue {

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: () => [] })
  private rides!: Ride[]

  @Prop({ required: true })
  private day!: Moment

  private ridesForDay(date: Moment) {
    return this.rides.filter((r) => moment(r.date).isSame(date, 'days'))
  }

  private edit(ride: Ride) {
    this.$emit('edit', ride)
  }
}
