var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.rides,"item-key":"key","options":_vm.pPagination,"server-items-length":_vm.pPagination.totalItems,"footer-props":{ itemsPerPageText: 'Zeilen:' }},on:{"update:options":function($event){_vm.pPagination=$event},"click:row":_vm.onClick},scopedSlots:_vm._u([{key:"item.template",fn:function(ref){
var item = ref.item;
return [(item.isTemplate || item.recurringRideId !== null)?_c('i',{staticClass:"icon icon-synchronize-arrow-clock"}):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateTime")(item.date)))]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('div',[_c('customer-name',{model:{value:(item.customer),callback:function ($$v) {_vm.$set(item, "customer", $$v)},expression:"item.customer"}})],1):_vm._e()]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("money")(item.cost)))]}},{key:"item.itinerary",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.itineraryName))]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('ride-state-badge',{attrs:{"ride":item}})]}},{key:"item.paymentMethod",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("ridePaymentMethod")(item.paymentMethod))+" "),(item.invoiceId)?_c('small',[_vm._v("(erstellt)")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }