import PagedMixin from '@/app/mixins/paged-mixin'
import Ride from '@/rides/models/ride'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesTable extends Mixins(PagedMixin) {

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: () => [] })
  private rides!: Ride[]

  get headers() {
    return [
      { text: '', value: 'template', sortable: false },
      { text: 'Auftragsnummer', value: 'jobNr' },
      { text: 'Zeitpunkt', value: 'date' },
      { text: 'Kunde', value: 'customer' },
      { text: 'Betrag', value: 'cost' },
      { text: 'Route', value: 'itinerary' },
      { text: 'Abrechnung', value: 'paymentMethod' },
      { text: 'Strecke / Kommentar', value: 'remarks' },
    ]
  }

  private onClick(ride: Ride) {
    this.$emit('edit', ride)
  }
}
