import authService from '@/users/services/auth-service'
import accountService from '@/users/services/account-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import User from '@/users/entities/user'

@Component
export default class Profile extends Vue {
    loading = false
    changePasswordDialog = false
    changeDataDialog = false
    password = ''
    passwordConfirmation = ''
    serverError = ''
    showConfirmation = false
    user: (User | null) = new User()

    async changePassword() {
        if (await this.$validator.validateAll()) {
            this.loading = true
            const res = await authService.changePasswordAsync(this.password, this.passwordConfirmation)
            this.loading = false

            if (res[0]) {
                this.password = ''
                this.passwordConfirmation = ''
                this.showConfirmation = true
            } else {
                this.serverError = res[1]
            }
        }
    }

    async cancelData() {
        this.user = await accountService.getCurrent()
        this.changeDataDialog = false
    }

    async saveData() {
        if (this.user) {
            await accountService.updateCurrent(<User>this.user)
            this.changeDataDialog = false
        }
    }

    async mounted() {
        this.user = await accountService.getCurrent()
    }
}
