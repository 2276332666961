import Period from '@/common/components/periods/period'
import Ride from '@/rides/models/ride'
import moment, { Moment } from 'moment'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RidesAgenda extends Vue {

  @Prop({ type: Boolean, default: false })
  private loading!: boolean

  @Prop({ default: () => [] })
  private rides!: Ride[]

  @Prop({ required: true })
  private period!: Period

  @Prop()
  private title!: string

  get days() {
    const list: Moment[] = []
    const end = moment(this.period.end)
    let start = moment(this.period.start)

    if (start > end) {
      return list
    }

    while (start <= end) {
      if (this.ridesForDay(start).length > 0) {
        list.push(start.clone())
      }
      start = start.add(1, 'day')
    }
    return list
  }

  private ridesForDay(date: Moment) {
    return this.rides.filter((r) => moment(r.date).isSame(date, 'days'))
  }

  private edit(ride: Ride) {
    this.$emit('edit', ride)
  }
}
