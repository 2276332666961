import 'babel-polyfill'

import Vue from 'vue'

import store from '@/app/store'
// Recovers the store from the local storage.
store.commit('init')

import '@/app/filters/index'
import '@/app/plugins/index'

import vuetify from '@/app/plugins/vuetify'
import router from '@/app/router'

import '@/component-registry'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: '64a926d5-f285-4fc2-abdd-72d6b584b4f8'
} });
appInsights.loadAppInsights();
appInsights.trackPageView();

Vue.config.productionTip = false

console.log(`MODE_ENV: ${process.env.NODE_ENV}`)
console.log(`VUE_APP_API_URL: ${process.env.VUE_APP_API_URL}`)

import App from '@/app/views/app.vue'

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
